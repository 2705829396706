import { Injectable, computed } from '@angular/core';

import { Observable, from } from 'rxjs';

import { BasicObjectSMI, NameObjectUsername, UsuarioPass, UsuarioSMI } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private controlador = computed(() => 'usuariosmi');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`todos`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/todos');
  }

  public obtenerActivos():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(``));
    // return this.http.get<coleccionDTO[]>(`${this.apiURL}`);
  }

  public obtenerAnalistas():Observable<NameObjectUsername[]>{
    return from(this.api.get<NameObjectUsername[]>(`ObtenerAnalistasLista`));
    // return this.http.get<coleccionCadenaDTO[]>(`${this.apiURL}/ObtenerAnalistasLista`);
  }

  public obtenerAnalistasActivos():Observable<NameObjectUsername[]>{
    return from(this.api.get<NameObjectUsername[]>(`ObtenerAnalistasListaActivos`));
    // return this.http.get<coleccionCadenaDTO[]>(`${this.apiURL}/ObtenerAnalistasLista`);
  }

  public obtenerAnalistasNombre():Observable<string[]>{
    return from(this.api.get<string[]>(`ObtenerAnalistas`));
    // return this.http.get<string[]>(`${this.apiURL}/ObtenerAnalistas`);
  }

  public obtenerAnalistasNombreActivo():Observable<string[]>{
    return from(this.api.get<string[]>(`ObtenerAnalistasActivos`));
    // return this.http.get<string[]>(`${this.apiURL}/ObtenerAnalistas`);
  }

  public obtenerPorId(userName: string): Observable<UsuarioSMI>{
    return from(this.api.get<UsuarioSMI>(`${userName}`));
    // return this.http.get<usuarioSMIDTO>(`${this.apiURL}/${userName}`);
  }

  public crear(usuario:UsuarioSMI){
    return from(this.api.post<UsuarioSMI, boolean>(``, usuario));
    // return this.http.post(this.apiURL,usuario);
  }

  public validarNombre(usuario:UsuarioSMI){
    return from(this.api.post<UsuarioSMI, boolean>(`validarNombre`, usuario));
    // return this.http.post<boolean>(`${this.apiURL}/validarNombre`,usuario);
  }

  public editar(userName: string, usuario:UsuarioSMI){
    return from(this.api.put<UsuarioSMI, boolean>(`${userName}`, usuario));
    // return this.http.put(`${this.apiURL}/${userName}`,usuario);
  }

  public actualizarPass(usuario: UsuarioPass){
    return from(this.api.post<UsuarioPass, boolean>(`actualizarPass`, usuario));

    // return this.http.post(`${this.apiURL}/actualizarPass`,usuario);
  }

  public borrar(userName: string){
    return from(this.api.delete(`${userName}`));
    // return this.http.delete(`${this.apiURL}/${userName}`);
  }

  public obtenerUsuariosNotas():Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`UsuariosNotas`));
    // return this.http.get<coleccionDTO[]>(this.apiURL + '/todos');
  }
}
